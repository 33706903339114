import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {WhiteLabelSettings} from "../models/WhiteLabelSettings";

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {

  logoURL: BehaviorSubject<string>;
  loginImage: BehaviorSubject<string>;
  brandingSettings: BehaviorSubject<WhiteLabelSettings>;
  defaultSettings: WhiteLabelSettings = {
    ui_logo: "",
    graph_logo: "",
    login_image: "",
    tertiary_colour: "#84ceeb",
    secondary_colour: "#dbf3c4",
    primary_colour: "#20e3cd",
    graph_tertiary: "#84ceeb",
    graph_secondary: "#dbf3c4",
    graph_primary: "#20e3cd",
    contrast_text_colour: "#222",
    navigation_color: "#222"
  }

  classMappings = [
    {
      label: 'primary_colour',
      class: '--primary',
      darker: true,
    },
    {
      label: 'secondary_colour',
      class: '--theme-secondary',
      darker: true,
    },
    {
      label: 'tertiary_colour',
      class: '--theme-tertiary',
      darker: false,
    },
    {
      label: 'contrast_text_colour',
      class: '--primary-contrast-text-color',
      darker: false,
    },
    {
      label: 'navigation_color',
      class: '--navigation_color',
      darker: false,
    }

  ]

  constructor(public http: HttpClient, public cookie: CookieService) {
    this.logoURL = new BehaviorSubject<any>('./assets/media/logos/brand_light4.svg');
    this.loginImage = new BehaviorSubject<any>('');
    this.brandingSettings = new BehaviorSubject<WhiteLabelSettings>(new WhiteLabelSettings());
  }

  public setColours(variable: string, value: string) {
    document.documentElement.style.setProperty(variable, value);
  }

  get currentBrand(): WhiteLabelSettings{

    return this.brandingSettings.value;

  }

  currentColor(key: 'primary_colour' | 'secondary_colour' | 'tertiary_colour' | 'graph_tertiary' | 'graph_secondary' | 'graph_primary'): any{

    const color =  this.currentBrand[key] ? this.currentBrand[key] : this.defaultSettings[key];
    return color;

  }

  set currentBrand(value){

    this.brandingSettings.next(value);

  }

  set customLogo(value: string) {
    this.logoURL.next(value);
  }

  get customLogo(): string {
    return this.logoURL.value;
  }

  set customLoginImage(value: string) {
    this.loginImage.next(value);
  }

  get customLoginImage(): string {
    return this.loginImage.value;
  }

  resetLogo() {

    this.logoURL = new BehaviorSubject<any>('./assets/media/logos/logo.svg');

  }

  resetLogin() {

    this.loginImage = new BehaviorSubject<any>('');

  }

  darkenColor(hsl: any) {


  }

  handleDownloadedSettings(data: WhiteLabelSettings): Promise<any> {

    return new Promise((resolve) => {

      this.currentBrand = data;

      if (data.ui_logo) {
        this.customLogo = data.ui_logo;
      }

      if (data.login_image) {
        this.customLoginImage = data.login_image;
      }

      Object.entries(data).forEach(entry => {
        // PROCESS COLOUR

        const [key, value] = entry;

        if(value){
          const mapping = this.classMappings.find((object) => {
            return object.label === key
          });

          if (mapping) {
            // SET BASE COLOUR
            this.setColours(mapping.class, value);
            // IF THE MAPPING REQUIRES A DARKER TONE
            if (mapping.darker) {
              const hslValue = this.HEXTOHSL(value) as any;
              let lightness = hslValue.l;
              hslValue.l = lightness *= 1 - 0.15;
              const hslString = 'hsl(' + Math.round(hslValue.h) + ',' + Math.round(hslValue.s) + '%,' + Math.round(hslValue.l) + '%)';
              this.setColours(mapping.class + '-darker', this.HSLToRGB(hslString, false));
            }
          }
        }

      });

      resolve(true);

    });

  }

  HSLToRGB(hsl: any, isPct: any) {
    let ex = /^hsl\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}|(\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2})\)$/i;
    if (ex.test(hsl)) {
      let sep = hsl.indexOf(",") > -1 ? "," : " ";
      hsl = hsl.substr(4).split(")")[0].split(sep);
      isPct = isPct === true;

      let h = hsl[0],
        s = hsl[1].substr(0, hsl[1].length - 1) / 100,
        l = hsl[2].substr(0, hsl[2].length - 1) / 100;

      // strip label and convert to degrees (if necessary)
      if (h.indexOf("deg") > -1)
        h = h.substr(0, h.length - 3);
      else if (h.indexOf("rad") > -1)
        h = Math.round(h.substr(0, h.length - 3) / (2 * Math.PI) * 360);
      else if (h.indexOf("turn") > -1)
        h = Math.round(h.substr(0, h.length - 4) * 360);
      // keep hue fraction of 360 if ending up over
      if (h >= 360)
        h %= 360;

      let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

      if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
      }

      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);

      if (isPct) {
        r = +(r / 255 * 100).toFixed(1);
        g = +(g / 255 * 100).toFixed(1);
        b = +(b / 255 * 100).toFixed(1);
      }

      return "rgb(" + (isPct ? r + "%," + g + "%," + b + "%" : +r + "," + +g + "," + +b) + ")";

    } else {
      return "Invalid input color";
    }
  }

  HEXTOHSL(hex: string) {
    let ex = /^#([\da-f]{3}){1,2}$/i;
    if (ex.test(hex)) {
      // convert hex to RGB first
      let r = 0 as any, g = 0 as any, b = 0 as any;
      if (hex.length == 4) {
        r = "0x" + hex[1] + hex[1];
        g = "0x" + hex[2] + hex[2];
        b = "0x" + hex[3] + hex[3];
      } else if (hex.length == 7) {
        r = "0x" + hex[1] + hex[2];
        g = "0x" + hex[3] + hex[4];
        b = "0x" + hex[5] + hex[6];
      }
      // then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

      if (delta == 0)
        h = 0;
      else if (cmax == r)
        h = ((g - b) / delta) % 6;
      else if (cmax == g)
        h = (b - r) / delta + 2;
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0)
        h += 360;

      l = (cmax + cmin) / 2;
      s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);

      return {
        h: h, s: s, l: l
      };

    } else {
      return "Invalid input color";
    }
  }

}
