import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {SubscriptionStatus} from "../models/SubscriptionStatus";
import {OnboardingFlags} from "../models/OnboardingFlags";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService{

  currentPaymentStatus$: Observable<SubscriptionStatus | null>;
  currentPaymentStatusSubject: BehaviorSubject<SubscriptionStatus | null> = new BehaviorSubject<any>(undefined);
  currentPackage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentFeatures: BehaviorSubject<any> = new BehaviorSubject<any>({});
  onboardingFlags: BehaviorSubject<OnboardingFlags | null> = new BehaviorSubject<OnboardingFlags | null>(null);

  constructor(public api: ApiService) {

  }

  refreshSubscriptionPackageOnboardStatus(): Promise<any>{
    return new Promise(resolve => {
      this.getPackageAndBalance().then((res)=>{
        this.onboardingFlags.next(res.OnBoardingFlags);
        this.currentPackage.next(res.Package);
        this.currentFeatures.next(res.MetaData);
        resolve(true);
      }).catch((err)=>{
        console.log(err);
      })
    });
  }

  checkPublicSubscriptionStatus(): Promise<any>{
    return this.api.apiGet('core/settings/status');
  }

  getPackageAndBalance(): Promise<any>{
    return this.api.apiGet('core/settings/package');
  }

  checkOwnerSubscriptionStatus(): Promise<any>{
    return this.api.apiGet('core/settings/payment/subscription');
  }

  createSetupIntent(): Promise<any>{
    return this.api.apiPost('core/settings/payment/setupIntent', {});
  }

  updatePaymentMethod(methodID: string): Promise<any>{
    return this.api.apiPost('core/settings/payment/saveMethod', {
      paymentMethod: methodID
    });
  }

  updatePaymentMethodSubscribe(methodID: string, planID: string): Promise<any>{
    return this.api.apiPost('core/settings/payment/saveMethodAndSubscribe', {
      paymentMethod: methodID,
      plan: planID
    });
  }

  confirmFreePlan(planID: string): Promise<any>{
    return this.api.apiPost('core/settings/payment/setupFree',{
      plan: planID
    });
  }

  endTrialEarly(): Promise<any>{
    return this.api.apiPost('core/settings/payment/endTrial', {});
  }

  debugLog(message:any){
    if(!environment.production){
      console.log(message);
    }
  }

}
