import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {TenantMember} from "../Interfaces/TenantMember";

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  unAuthenticatedOptions: any;
  currentTenant: Observable<any>;
  currentTenantSubject: BehaviorSubject<any>;
  currentDomainSubject: BehaviorSubject<any>;
  availableTenants: BehaviorSubject<TenantMember[]> = new BehaviorSubject<TenantMember[]>([]);

  constructor(public http: HttpClient, public cookie: CookieService) {
    this.currentTenantSubject = new BehaviorSubject<any>(undefined);
    this.currentDomainSubject = new BehaviorSubject<any>(undefined);
    this.currentTenant = this.currentTenantSubject.asObservable();
    this.unAuthenticatedOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }),
    };

  }

  get currentTenantValue(): any {
    return this.currentTenantSubject.value;
  }

  set currentTenantValue(user: any) {
    this.currentTenantSubject.next(user);
  }

  public appendTenantHeader(){
    const options = this.unAuthenticatedOptions;
    options.headers = options.headers.set('X-Tenant', this.currentTenantValue)
    return options;
  }
  public getPublicBranding(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/white-label-ui', this.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  public reverseLookup(data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.host_domain + '/api/reverseIdentify', data, this.unAuthenticatedOptions)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  public getUser(headers: any): Promise<any>{
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.host_domain + '/api/user', headers)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

}
