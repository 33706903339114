import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SubscribedGuard} from "./guards/subcribed.guard";
import {TenantGuard} from "./guards/tenant.guard";
import {AuthGuard} from "@auth0/auth0-angular";

// ULTRAGUARD HANDLES AUTH, TENANT IDENTIFICATION, WHITE LABELLING
// SUBSCRIBED GUARD CHECKS THEY HAVE PAID

export const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'tenant',
            loadChildren: () =>
              import('./modules/tenant/tenant.module').then((m) => m.TenantModule),
          },
          {
            path: '',
            canActivate: [TenantGuard],
            children: [
              {
                path: 'setup',
                canActivate: [],
                loadChildren: () =>
                  import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
              },
              {
                path: '',
                canActivate: [SubscribedGuard], // THEN RETRIEVE THE USER AND SETTINGS
                loadChildren: () =>
                  import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
              }
            ]
          }
        ]
      },

      { path: '**', redirectTo: 'error/404' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
