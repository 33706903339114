import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {CurrentUser} from "../models/CurrentUser";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  currentUser$: Observable<CurrentUser | any |  null>;
  currentUserSubject: BehaviorSubject<CurrentUser | any | null> =  new BehaviorSubject<CurrentUser | any | null>(null);

  currentUserRole$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public api: ApiService) {
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  set currentUserRole(role: any) {
    this.currentUserRole$.next(role);
  }

  get currentUserRole(): any {
    return this.currentUserRole$.value;
  }

}
