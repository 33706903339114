import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import {CookieService} from "ngx-cookie-service";
import {RECAPTCHA_SETTINGS, RecaptchaSettings} from "ng-recaptcha";
import {NgxStripeModule} from "ngx-stripe";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import {TimeagoModule} from "ngx-timeago";
import {BlankComponent} from "./blank/blank.component";
import {NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule} from "ngx-zendesk-webwidget";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import {AgmCoreModule, GoogleMapsAPIWrapper} from "@lvmaps/core";

registerLocaleData(en);

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'qrpay.zendesk.com';
  callback(zE: any) {
    // You can call every command you want in here
    // zE('webWidget', 'hide');
    // zE('webWidget', 'show');
  }
}

@NgModule({
  declarations: [AppComponent, BlankComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    NgxStripeModule.forRoot(environment.stripe_public_key),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: false,
      useRefreshTokensFallback: false,
      authorizationParams: {
        redirect_uri: environment.auth0.authorizationParams.redirect_uri,
        audience: environment.auth0.authorizationParams.audience
      },
      httpInterceptor: environment.auth0.httpInterceptor
    }),
    AppRoutingModule,
    GoogleTagManagerModule.forRoot({
      id: environment.tagManagerID,
    }),
    TimeagoModule.forRoot(),
    NgbModule,
    FormsModule,
    NzModalModule,
    NzDrawerModule
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey
      } as RecaptchaSettings
    },
    CookieService,
    GoogleMapsAPIWrapper,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
