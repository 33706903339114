import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import {Observable} from "rxjs";
import {SubscriptionService} from "../services/subscription.service";
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class SubscribedGuard implements CanActivate {

  constructor(
    private subService: SubscriptionService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      this.debugLog('Subscribe Guard Started');

      let redirect = '/setup/subscribe';

      const subscription = this.subService.currentPaymentStatusSubject.value;

      if(!subscription){

        // CHECK WITH THE SERVER ABOUT THE SUBSCRIPTION STATUS
        this.subService.checkPublicSubscriptionStatus().then((SubscriptionStatus) =>{
          // THERE IS AN EXISTING SUBSCRIPTION
          this.subService.currentPaymentStatusSubject.next(SubscriptionStatus);

          if(this.canAccess()){

            // THEY ARE ALLOWED TO ACCESS THE SOFTWARE, NOW LETS GET THEIR TOKEN BALANCE
              this.subService.refreshSubscriptionPackageOnboardStatus().then((res)=>{
                resolve(true);
              }).catch((err)=>{
                console.log(err);
              })

          }else{
            // SUBSCRIPTION IS INVALID
            this.router.navigateByUrl(redirect)
            resolve(false);
          }

        }).catch((SubscriptionError)=>{

          this.debugLog(SubscriptionError);
          this.subService.currentPaymentStatusSubject.next(null);
          this.router.navigateByUrl(redirect)
          resolve(false);
        });

      }else{

        // SUBSCRIPTION HAS ALREADY BEEN CHECKED AND STORED IN SUBJECT, DON'T REPEAT IT TO REDUCE MEMORY / CPU LOAD
        resolve(true);

      }

    });
  }

  canAccess(): boolean {

    const subscription = this.subService.currentPaymentStatusSubject.value;

    if(!subscription){
      return false;
    }else{

      return subscription.StatusFlags.OnTrial ||
        subscription.StatusFlags.DueToEnd ||
        subscription.StatusFlags.Active;

    }

  }

  debugLog(message:string){
    if(!environment.production){
      console.log(message);
    }
  }

}
