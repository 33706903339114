import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {TenantService} from "./tenant.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService{

  constructor(public http: HttpClient, private tenant: TenantService) {
  }

  constructOptions(upload = false, tenant = true){
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json'
      })
    };
    if(upload){
      options.headers = options.headers.set('Content-Type', 'application/json');
    }
    if(tenant){
      options.headers = options.headers.set('X-Tenant', this.tenant.currentTenantValue);
    }

    return options;
  }
  getIframeURL(addressType: string, backgroundColor: string, labelColor: string): string{

    return  environment.httpProtocol +
            this.tenant.currentTenantValue +
            environment.tenant_domain + '/enquire?' +
            'addressType=' + addressType +
            '&bgColor=' + backgroundColor +
            '&labelColor=' + labelColor;
  }

  getAPIUrl(): string{

    return  environment.httpProtocol +
            this.tenant.currentTenantValue +
            environment.tenant_domain + '/api/leads';
  }

  getSettings(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/' + '/settings', this.constructOptions())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiGet(url: string, tenant = true): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/' + url, this.constructOptions(false, tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPost(url: string, data: any, upload: boolean = false, tenant: boolean = true): Promise<any>{

    console.log('FIRING POST');
    console.log(environment.httpProtocol + environment.tenant_domain + '/api/' + url);

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/' + url, data, this.constructOptions(upload, tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            console.log(err);
            reject(err);
          },
        );
    });

  }

  apiPut(url: string, data: any, upload: boolean = false, tenant: boolean = true): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .put(environment.httpProtocol + environment.tenant_domain + '/api/' + url, data, this.constructOptions(upload, tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPatch(url: string, data: any, upload: boolean = false, tenant: boolean = true): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .patch(environment.httpProtocol + environment.tenant_domain + '/api/' + url, data, this.constructOptions(upload, tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  mediaPost(url: string, data: any, upload: boolean = false, tenant: boolean = true): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.media_server + '/api/' + url, data, this.constructOptions(upload, tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiDelete(url: string, upload: boolean = false, tenant: boolean = true): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .delete(environment.httpProtocol + environment.tenant_domain + '/api/' + url, this.constructOptions(upload,tenant))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getLoggedInUser(): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/user', this.constructOptions(false, false))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getPublicInvitationData(invitationID: string): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/invitations/' + invitationID, this.constructOptions())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  publicPost(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/' + url, data, this.constructOptions())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  submitTicket(url: string, data: any): Promise<any>{

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/submitTicket', data, this.constructOptions())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

}
